<template>
   <v-container class="unreactive-margins">
    <v-data-table
      :headers="headers"
      :items="sitesData"
      class="inverse-header-color"
    >
      <template #[`item.type`]="{ item }">
        <v-chip v-if="siteType(item) != 'TBD'" small :color="chipColor(siteType(item))">{{ siteType(item) }}</v-chip>
        <v-tooltip v-else top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" style="display: inline;">
              <v-chip v-if="siteType(item)" small :color="chipColor(siteType(item))">{{ siteType(item) }}</v-chip>
            </div>
          </template>
          <span>Site must have at least one Segment before it is assigned a type.</span>
        </v-tooltip>
      </template>
      <template #[`item.haulsSent`]="{ item }">
        {{ item.sent ? `${item.sent.actual}/${item.sent.scheduled}` : '0/0' }}
      </template>
      <template #[`item.haulsReceived`]="{ item }">
        {{ item.sent ? `${item.received.actual}/${item.received.scheduled}` : '0/0' }}
      </template>
      <template #[`item.status`]="{ item }">
        <v-chip small :color="item.status ? chipColor(item.status.status) : ''">{{ item.status.status }}</v-chip>
      </template>
      <template #[`item.actions`]="{ item }">
          <v-btn v-if="isGeneralContractor" elevation="0" x-small class="mr-1" color="info" outlined @click="viewInfo(item)">View</v-btn>
          <v-btn v-if="isGeneralContractor" elevation="0" x-small class="mr-1" color="info" outlined @click="viewSegment(item)">Segments</v-btn>
          <v-btn v-if="isGeneralContractor" x-small elevation="0" color="info">Edit</v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { chipColorClass } from '@/helpers/StylingHelper.js'
import { mapState, mapGetters } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex';

export default {
  props: {
    sitesData: {
      type: Array,
      default: () => {}
    }
  },
  data: () => ({
    generalContractorCompanyTypeId: 1
  }),
  computed: {
    ...mapState({
      // companies: (state) => state.comp.companies
    }),
    ...mapGetters({
      findClaim: 'auth/findClaim',
      selectedCompanyProfile: 'auth/selectedCompanyProfile'
    }),
    isGeneralContractor () {
      const companyProfile = this.selectedCompanyProfile
      return companyProfile && companyProfile.companyTypeId === this.generalContractorCompanyTypeId
    },
    headers () {
      const headers = []
      headers.push({ text: 'Type', value: 'type' })
      headers.push({ text: 'Site Code', value: 'siteCode' })
      headers.push({ text: 'Name', value: 'name', cellClass: 'font-weight-bold' })
      headers.push({ text: 'Address', value: 'address' })
      headers.push({ text: 'Geo Location', value: 'geoLocation' })
      if (this.sitesData.some((site) => site.isDonor)) headers.push({ text: 'Hauls Sent', value: 'haulsSent', sortable: false })
      if (this.sitesData.some((site) => site.IsReceiver)) headers.push({ text: 'Hauls Received', value: 'haulsReceived', sortable: false })
      headers.push({ text: 'Site Status', value: 'status' })
      headers.push({ text: 'Actions', value: 'actions', align: 'end' })
      return headers
    }
  },
  methods: {
    viewInformation (item) {
      this.$emit('viewInformation', item)
    },
    viewSegment (item) {
      this.$emit('viewSegment', item)
    },
    viewInfo (item) {
      this.$emit('viewInfo', item)
    },
    chipColor (name) {
      return chipColorClass(name)
    },
    siteType (site) {
      if (site.isDonor && site.isReceiver) return 'Hybrid'
      if (site.isDonor) return 'Exporter'
      if (site.isReceiver) return 'Importer'
      return 'TBD'
    }
  }
}
</script>
<style>
  .container.unreactive-margins {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100% !important;
    max-width: 100% !important;
  }
</style>
