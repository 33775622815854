<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-dialog v-model="formOpen" persistent style="width:80%" width="80%" min-width="600px">
        <v-card style="width:100%">
          <v-card-title>New Site</v-card-title>
          <v-card-text>
            <v-container style="width:100%; max-width:100%;" class="no-gutters">
              <v-form v-model="valid" ref="form">
                <v-row>
                  <v-col cols="3">
                    <v-text-field v-model="submitData.name" :rules="[rules.required]">
                      <template #label> Name<red-asterisk /> </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete v-model="submitData.siteManagerId" :rules="[rules.required]" :items="users">
                      <template #label> Site Manager<red-asterisk /> </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <vuetify-google-autocomplete
                      id="google"
                      ref="google"
                      classname="form-control"
                      v-on:placechanged="getAddressData"
                      :country="'ca'"
                    ></vuetify-google-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5">
                    <v-text-field v-model="submitData.email" :rules="[rules.required, rules.emailRules]">
                      <template #label> Landlord Email Address<red-asterisk /> </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="submitData.phone" :rules="[rules.required, rules.phoneRules]">
                      <template #label> Landlord Phone<red-asterisk /> </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field label="Extension" v-model="submitData.extension" :rules="[rules.extensionRules]"/>
                  </v-col>
                </v-row>

                <v-row class="text-right">
                  <v-spacer></v-spacer>
                    <v-btn variant="flat" class="mr-2"  @click="closeForm">Close</v-btn>
                    <v-btn variant="outlined" @click="submitSite">Save</v-btn>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'

export default {
  components: {
    VuetifyGoogleAutocomplete
  },
  props: {
    formIsOpen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    formOpen: false,
    valid: false,
    provinces: [],
    cities: [],
    users: [],
    defaultSubmitData: {
      siteManagerId: '',
      name: '',
      email: '',
      phone: '',
      extension: null,
      address: {
        addressLine1: null,
        postalCode: null,
        cityName: null,
        cityId: null,
        province: null,
        provinceId: null,
        country: null,
        placeId: null,
        latitude: null,
        longitude: null
      }
    },
    submitData: {},
    rules: {
      required: value => !!value || 'Required.',
      emailRules: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Must be a valid e-mail.'
      },
      postalCodeRules: value => {
        const pattern = /^(?:[A-Za-z]\d){3}$/
        return pattern.test(value) || 'Must be a valid postal code.'
      },
      phoneRules: value => {
        const pattern = /^(\+?1-?)?\(?\d{3}\)?-?\d{3}-?\d{4}$/
        return pattern.test(value) || 'Must be a valid phone number.'
      },
      extensionRules: value => {
        // TODO ensure int
        if (value) {
          const pattern = /^\d+$/
          return pattern.test(value) || 'Must be a valid extension.'
        } else return true
      }
    }
  }),
  watch: {
    formIsOpen: {
      handler (value) {
        this.formOpen = value
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      if (this.provinces.length <= 0) {
        await this.getProvinces()
      }
      if (!this.submitData.address) { // need to update to watcher instead of created lifecyle hook in future
        this.resetForm()
      }
      if (this.users.length <= 0) {
        await this.getUsers()
      }
    },
    async submitSite () {
      if (!this.$refs.form.validate()) return
      const newRow = await this.$api.sites.post(this.submitData)
      if (newRow.id) {
        this.$store.dispatch('app/showSuccess', 'Site successfully created')
        this.$emit('submit-form', newRow)
        this.closeForm()
      } else {
        this.$store.dispatch('app/showError', 'Failed to create Site')
      }
    },
    closeForm () {
      this.$refs.form.resetValidation()
      this.resetForm()
      this.$emit('close-form')
    },
    resetForm () {
      this.submitData = JSON.parse(JSON.stringify(this.defaultSubmitData))
      if (this.$refs.google) this.$refs.google.clear()
    },
    async getCitiesByProvinceId (id) {
      const params = [{ name: 'provinceId', value: id }]
      const res = await this.$api.cities.get(null, params)
      this.cities = res
    },
    async getProvinces () {
      const res = await this.$api.provinces.get()
      this.provinces = res
    },
    async handleProvinceInput (value) {
      if (value != null) {
        await this.getCitiesByProvinceId(value)
      }
    },
    async getUsers () {
      const params = [{ name: 'companyId', value: this.$store.state.comp.companies[0].id }]
      const res = await this.$api.users.get(null, params)
      res.forEach(u => {
        this.users.push({ text: `${u.firstName} ${u.lastName}`, value: u.id })
      })
    },
    async getAddressData (addressData, placeData) {
      this.submitData.address.addressLine1 = `${addressData.street_number ? `${addressData.street_number} ` : ''}${addressData.route}`
      if (addressData.postal_code) {
        this.submitData.address.postalCode = addressData.postal_code
      } else if (placeData && placeData.address_components && placeData.address_components.some(x => x.types && x.types.some(y => y === 'postal_code' || y === 'postal_code_prefix'))) {
        this.submitData.address.postalCode = placeData.address_components.find(x => x.types.some(y => y === 'postal_code' || y === 'postal_code_prefix')).long_name
      }
      this.submitData.address.cityName = addressData.locality
      this.submitData.address.province = addressData.administrative_area_level_1 // gives 2 character province code
      this.submitData.address.googlePlaceId = addressData.place_id
      this.submitData.address.latitude = addressData.latitude
      this.submitData.address.longitude = addressData.longitude
      this.submitData.address.country = addressData.country
    }
  }
}
</script>

<style>
  .v-dialog > .v-overlay__content {
    width:80%;
  }
</style>
