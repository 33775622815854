<template>
  <div>

    <site-form :form-is-open="formIsOpen" @close-form="closeForm" @submitForm="createSite"/>

    <v-row class="mx-2 mt-1">
      <v-col cols="9">
        <v-tabs v-model="activeTab">
          <v-tab value='table'>Table</v-tab>
          <v-tab value='cards'>Cards</v-tab>
          <!--<v-tab value='map'>Map</v-tab>-->
        </v-tabs>
      </v-col>
      <v-col cols="3" class="text-right justify-end d-flex align-center">
        <v-btn v-if="isGeneralContractor" elevation="0" outlined prepend variant="outlined" @click="openSiteForm"><v-icon>mdi-plus</v-icon>New Site</v-btn>
      </v-col>
    </v-row>

    <v-window v-model="activeTab">
      <v-window-item>
        <sites-table :sites-data="sitesData" @viewSegment="(item) => viewSegment(item)" @viewInfo="(item) => viewInfo(item)" />
      </v-window-item>

      <v-window-item>
        <sites-card :sites-data="sitesData" />
      </v-window-item>

      <v-window-item>
        <sites-map :sites-data="sitesData" :selected-site="selectedSite" />
      </v-window-item>
    </v-window>
  </div>

</template>

<script>
// import { ref } from 'vue'
import SitesTable from '@/components/sites/SitesTable.vue'
import SiteForm from '@/components/sites/SiteForm.vue'
import SitesMap from '@/components/sites/SitesMap.vue'
import SitesCard from '@/components/sites/SitesCard.vue'
import { createPaginationObject } from '@/helpers/PaginationHelper.js'
import { mapState, mapGetters } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex'
import { formatAddress } from '@/helpers/FormatHelper.js'

export default {
  components: {
    SitesTable,
    SiteForm,
    SitesMap,
    SitesCard
  },
  data: () => ({
    activeTab: 0,
    sitesData: [],
    selectedSite: {},
    formIsOpen: false,
    filters: [],

    generalContractorCompanyTypeId: 1
  }),
  computed: {
    ...mapState({
      darkMode: (state) => state.app.isDarkMode,
      selectedSiteId: (state) => state.site.selectedSiteId,
      companies: (state) => state.comp.companies
    }),
    ...mapGetters({
      findClaim: 'auth/findClaim',
      selectedCompanyProfile: 'auth/selectedCompanyProfile'
    }),
    isGeneralContractor () {
      const companyProfile = this.selectedCompanyProfile
      return companyProfile && companyProfile.companyTypeId === this.generalContractorCompanyTypeId
    }
  },
  watch: {
    // eslint-disable-next-line quote-props
    selectedSiteId: {
      handler (value) {
        if (value) {
          this.loadSites()
        }
      },
      deep: true,
      immediate: true
    },
    selectedCompanyProfile: {
      handler () {
        this.loadSites()
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      await this.$store.dispatch('action/refreshActionCount')
      this.loadSites()
    },
    async loadSites () {
      const search = createPaginationObject(null, null, this.filters)
      const res = await this.$api.sites.read(search)
      this.sitesData = res.sites.map((site) => {
        return {
          ...site,
          address: site.address ? formatAddress(site.address) : '-',
          geoLocation: site.address && site.address.latitude && site.address.longitude ? `${site.address.latitude}, ${site.address.longitude}` : '-'
        }
      }
      )
    },
    openSiteForm () {
      this.formIsOpen = true
    },
    closeForm () {
      this.loadSites()
      this.formIsOpen = false
    },
    createSite (row) {
      this.sitesData.push(row)
    },
    viewSegment (item) {
      this.$store.dispatch('site/selectSite', item.id)
      this.$router.push('/segments')
    },
    viewInfo (item) {
      this.$store.dispatch('site/selectSite', item.id)
      this.$router.push('/site')
    }
  }
}

</script>
<style>
.v-tabs.theme--dark div.v-tabs-bar {
  background-color: #121212 !important;
}
</style>
