<template>
  <v-container>
    <v-row>
      <v-col v-for="site in sitesData" :key="site.id" cols="4">
        <v-card>
          <v-row>
            <v-col cols="9">
              <v-card-title>
                <span v-html="site.address" />
              </v-card-title>
            </v-col>
            <v-col cols="3" class="text-right">
              <v-chip class="mt-2 mr-2" :color="chipColor(siteType(site))" outlined>{{ siteType(site) }}</v-chip>
            </v-col>
          </v-row>
          <v-card-subtitle class="mb-2">
            <span v-html="site.geoLocation" />
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { chipColorClass } from '@/helpers/StylingHelper.js'
export default {
  props: {
    sitesData: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    siteType (site) {
      if (site.isDonor && site.IsReceiver) return 'Hybrid'
      if (site.isDonor) return 'Exporter'
      if (site.IsReceiver) return 'Importer'
      return 'TBD'
    },
    chipColor (name) {
      return chipColorClass(name)
    }
  }
}

</script>
