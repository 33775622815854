<template>
   <sites-template />
</template>
<script>

// Components
import SitesTemplate from '@/components/sites/SitesTemplate.vue'

export default {
  name: 'SitesView',
  components: {
    SitesTemplate
  }
}

</script>
