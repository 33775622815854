<template>
  <v-container>
    <v-row>
      <v-col class="text-center">
        <iframe id="mapFrame" :width="mapWidth" height="650" allowfullscreen/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  props: {
    sitesData: {
      type: Array,
      default: () => []
    },
    selectedSite: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    selectedSite: {
      immediate: true,
      handler (value) {
        this.loadMap(value)
      }
    }
  },
  computed: {
    mapWidth () {
      return window.innerWidth * 0.8
    }
  },
  methods: {
    async loadMap (site) {
      await this.$nextTick
      const coordinates = site && site.address ? `${site.address.addressLine1}, ${site.address.city.name}, ${site.address.city.province.shortName} ${site.address.postalCode}` : '3464 Semenyk Ct, Mississauga, ON'
      document.getElementById('mapFrame').src = `https://www.google.com/maps/embed/v1/place?key=AIzaSyDQy96cw704Kbb_Hsb2uP8sqV_17zcP600&q=${coordinates}`
    }
  }
}

</script>
